@import "normalize";

// Colors
$amazon-orange: #f90;
$google-blue: #4285f4;

$xapp-black: #000;
$xapp-blue: #3cb6e9;
$xapp-blue-dark: #0e2244;
$xapp-cerise: #e84296;
$xapp-green: #39d75a;
$xapp-red: #e94949;
$xapp-white: #ffffff;

// Sizes
$bp-tablet-port: 767px;
$bp-tablet-land: 1023px;

// Mixins
@mixin transitioned {
    transition: .25s linear all;
}

* {
    box-sizing: border-box;
}

body {
    font-family: "Roboto", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Overpass", serif;
}

a {
    @include transitioned;
    color: $xapp-blue;

    &:hover {
        color: $xapp-cerise;
    }
}

footer p:last-child {
    margin: 0;
}

fieldset {
    border: 0;
    padding: 0;
    margin-bottom: .5em;
}

#root {
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

#reset fieldset {
    border-top: 1em;
}

label {
    display: block;
    font-weight: bold;
    padding-bottom: .5em;
    color: $xapp-blue-dark;
}

button,
input {
    width: 100%;
    padding: 1em;
    border: 2px solid rgba($xapp-blue-dark, .5);

    &::placeholder {
        color: rgba($xapp-black, .5);
    }

    &:focus {
        border-color: $xapp-blue-dark;
        outline: 0;
    }

    &:focus::placeholder {
        color: rgba($xapp-black, .25);
    }
}

input[type="email"]:disabled,
input[type="password"]:disabled {
    cursor: not-allowed;
    opacity: .25;
}

input[type="checkbox"] {
    width: auto;
}

.submit-button,
input[type="submit"] {
    @include transitioned;
    background-color: $xapp-cerise;
        border: 0;
    cursor: pointer;
    width: 100%;
    color: $xapp-white;
    font-weight: bold;
    font-size: 1em;
    position: relative;

    &:hover {
        background-color: darken($xapp-cerise, 15%);
    }

    &:disabled {
        background-color: rgba($xapp-cerise, .5);
        cursor: not-allowed;
    }
}

.is-working {
    color: rgba($xapp-white, 0);

    &:after {
        background: url("../img/spinner.gif") no-repeat center center;
        background-size: 40px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        content: "";
        display: block;
        width: 20px;
        height: 20px;
    }
}

.sr-only {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

// Styles
#main {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: $xapp-white;

    @media screen and (min-width: $bp-tablet-port + 1) {
        flex-direction: row;
    }
}

#primary {
    align-items: center;
    display: flex;
    flex: 2 1 auto;
    width: 100%;
    flex-direction: column;
    padding: 1em;
    overflow-y: scroll;
    overflow-x: hidden;
    text-align: center;
    position: relative;

    @media screen and (min-width: $bp-tablet-port + 1) {
        width: 33.33333%;

        h1 {
            font-size: 2em;
        }
    }

    @media screen and (min-height: $bp-tablet-port + 1) {
        justify-content: center;
    }

    h1 {
        display: flex;
        color: $xapp-blue-dark;
        margin: 0 0;
        justify-content: center;
        align-items: center;
        padding: 0 .5em;
    }

    .logo {
        width: 75px;
        margin-right: 15px;
    }
}

.guts {
    padding-top: 2em;
}

button {
    @include transitioned;
    display: block;
    width: 100%;
    padding: 1em;
    color: $xapp-white;
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 1em;
    border: 0;
    cursor: pointer;
}

#social {
    padding-top: 1em;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;

    .social {
        position: relative;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .amazon {
        background-color: $amazon-orange;

        &:hover {
            background-color: darken($amazon-orange, 15%);
        }

        &:disabled {
            background-color: rgba($amazon-orange, .5);
            cursor: not-allowed;
        }
    }

    .google {
        background-color: $google-blue;

        &:hover {
            background-color: darken($google-blue, 15%);
        }

        &:disabled {
            background-color: rgba($google-blue, .5);
            cursor: not-allowed;
        }
    }

    .email {
        border: 2px solid $xapp-blue-dark;
        background-color: white;
        color: $xapp-blue-dark
    }
}

#signup_form,
#login_form {
    width: 100%;
    margin: 0 auto;
    max-width: 400px;
    position: relative;
}

#signup_form {
    padding-top: 2em;
}

.or {
    text-align: center;

    color: rgba($xapp-black, .75);
}

#secondary {
    display: none;
    flex: 3 1 auto;
    text-align: center;
    color: $xapp-white;
    flex-direction: column;
    padding: 2em 2em 1em;

    @media screen and (min-width: $bp-tablet-port + 1) {
        background: url("../img/splash.png") no-repeat center 65%, $xapp-blue-dark;
        display: flex;
        width: 66.66666%;
    }

    @media screen and (min-width: $bp-tablet-port + 1) and (orientation: portrait) {
        background-size: 85%;
    }
}

.sizzle {
    text-align: center;
    color: $xapp-white;
    flex: 1 1 auto;

    p {
        font-size: 1.75em;
        max-width: 810px;
        margin: 0 auto;
    }

    ul {
        list-style: none;
        padding: 1em 0 0;

        li {
            display: inline-block;
            margin: 0 .5em;
        }
    }

    a {
        @include transitioned;
        font-size: 1em;
        text-decoration: none;
        color: $xapp-white;
        padding: .5em 1em;
        border: 2px solid rgba($xapp-white, 1);

        &:hover {
            color: $xapp-cerise;
            border: 2px solid rgba($xapp-cerise, 1);
        }
    }
}

.final-thought {
    padding: 0 80px 1em;
}

.error-message,
.success-message {
    h2 {
        margin-top: 0;
    }

    background-color: rgba($xapp-red, .25);
    padding: .5em;
    width: 100%;
    border-radius: .25em;
    font-weight: bold;
    color: rgba($xapp-red, .75);
    margin: 1em auto;
}

.success-message {
    background-color: rgba($xapp-green, .25);
    color: darken($xapp-green, 25%);
}

.flex {
    display: flex;
    align-items: center;
    width: 100%;
}

.col {
    flex-direction: column;
}

.just-center {
    justify-content: center;
}

.flex-grow {
    flex-grow: 1;
}

.flex-no-grow {
    flex-grow: 0;
}

.validation {
    padding: 0 1em;
    list-style: none;
    text-align: left;

    li {
        display: flex;
    }

    .stripe {
        background: rgba($xapp-black, .1);
    }

    ul {
        padding: 0;
        font-size: .9em;
        padding-left: 1em;
        width: 100%;
    }
}

.label {
    padding-right: .5em;

    .is-valid & {
        color: lighten($xapp-black, 75%);
    }
}

.is-valid .sub-label {
    text-decoration: line-through;
}

.status {
    font-size: 16px;
    color: $xapp-green;
}

.terms span {
    padding-left: .5em;
}
